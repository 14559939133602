import React, { useRef, useEffect, useState } from "react";

const VideoComponent = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();
        const isInViewport =
          rect.top <
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.bottom > 0;

        setIsVisible(isInViewport);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("Is video visible:", isVisible);
    if (isVisible && videoRef.current) {
      videoRef.current.load(); // Ensure the video is loaded
      videoRef.current.play().catch((error) => {
        console.error("Video play failed:", error);
      });
    }
  }, [isVisible]);

  const styles = {
    videoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "350px",
      overflow: "hidden",
      backgroundColor: "black",
      padding: "20px",
    },
    video: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
    },
  };

  return (
    <div style={styles.videoContainer}>
      <video
        ref={videoRef}
        style={styles.video}
        src={videoSrc}
        muted
        playsInline
        loop={false}
        controls={false}
        preload="auto"
      />
    </div>
  );
};

export default VideoComponent;
