import React from "react";

const Avatar = ({ imageSrc, name, isLeftAligned = true }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: isLeftAligned ? "row" : "row-reverse", // Aligns left or right
      alignItems: "center",
      gap: "5px", // Space between image and text
      marginBottom: "10px",
    },
    image: {
      width: "25px", // Adjust size as needed
      height: "25px",
      borderRadius: "50%",
      border: "1.5px solid white", // White border
      objectFit: "cover", // Ensures the image covers the area without distortion
    },
    name: {
      fontSize: "16px", // Adjust text size as needed
      fontWeight: "500",
      color: "white",
      textAlign: isLeftAligned ? "left" : "right", // Align text accordingly
    },
  };

  return (
    <div style={styles.container}>
      <img src={imageSrc} alt={`${name}'s avatar`} style={styles.image} />
      <span style={styles.name}>{name}</span>
    </div>
  );
};

export default Avatar;
