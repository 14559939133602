import React, { useEffect, useState } from "react";
import logoPlaceholder from "../../assets/landing/omi_logo.svg";
import shoes from "../../assets/landing/shoes.jpg";
import jeans from "../../assets/landing/jeans.png";
import jacket from "../../assets/landing/jacket.png";
import poloShirt from "../../assets/landing/polo_shirt.png";
import loeweJeans from "../../assets/landing/loewe-jeans.png";
import boots from "../../assets/landing/boots.png";
import Card from "./card";
import Conversation from "./conversation";
import ConversationImg from "./conversation-image";
import VideoThumbnail from "./video-thumbnail";
import Footer from "./footer";
import VideoComponent from "./video-component";
import drawingVideo from "../../assets/landing/drawing-video.m4v";

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 742);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 742);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    landingPage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "black",
      color: "white",
      minHeight: "100vh",
      padding: "20px",
      boxSizing: "border-box",
    },
    logo: {
      width: "100px",
      position: "absolute",
      top: "20px",
      left: "50px",
      zIndex: 10,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      width: isMobile ? "90%" : "60%",
    },
    videoThumbnailContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
      marginTop: "120px",
    },
    cardsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "20px",
      marginTop: "50px",
      marginBottom: "100px",
      width: "100%",
      maxWidth: "1200px",
    },
    videoSection: {
      width: "100%",
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
    },
    content: {
      textAlign: "center",
      fontSize: isMobile ? "16px" : "18px",
      lineHeight: "1.6rem",
      marginTop: "40px",
    },
  };

  return (
    <div style={styles.landingPage}>
      <img src={logoPlaceholder} alt="Logo" style={styles.logo} />

      <div style={styles.contentContainer}>
        <div style={styles.videoThumbnailContainer}>
          <div style={styles.videoThumbnail}>
            <VideoThumbnail videoUrl="https://youtu.be/BqEtMXWvlMA" />
          </div>

          {/* conversation 1 */}
          <div style={styles.conversation}>
            <Conversation />
          </div>
        </div>

        <div style={styles.cardsContainer}>
          <Card
            imageSrc={jacket}
            label="Mini Leather Blouson Jacket"
            subtitle={"Our Legacy"}
            price={1080}
          />
          <Card
            imageSrc={jeans}
            label="Wesley Straight-Leg Distressed Jeans"
            subtitle={"Celine Homme"}
            price={186}
          />
          <Card
            imageSrc={shoes}
            label="Maison Margiela"
            subtitle={"Replica Leather & Suede Sneakers"}
            price={500}
          />
        </div>

        {/* conversation 2 */}
        <div style={styles.conversation}>
          <ConversationImg />
        </div>
      </div>

      <div style={styles.cardsContainer}>
        <Card
          imageSrc={poloShirt}
          label="Ribbed Merino Wool Polo Shirt"
          subtitle={"Maria McManus"}
          price={1080}
        />
        <Card
          imageSrc={loeweJeans}
          label="Anagram Appliqued Boyfriend Jeans "
          subtitle={"Loewe"}
          price={186}
        />
        <Card
          imageSrc={boots}
          label="Ona Crinkled-Leather Knee Boots"
          subtitle={"Khaite"}
          price={500}
        />
      </div>

      {/* Video Section */}
      <div style={styles.videoSection}>
        <VideoComponent videoSrc={drawingVideo} zoomLevel={0.5} />
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
