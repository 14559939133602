import React from "react";
import PropTypes from "prop-types";
import ThumbnailImage from "../../assets/landing/video-frame.png";

const VideoThumbnail = ({ videoUrl }) => {
  const styles = {
    videoThumbnail: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5%",
      overflow: "hidden",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      position: "relative",
      margin: "0 auto", // Center horizontally
      marginBottom: "30px",
    },
    videoImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    playButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50px",
      height: "50px",
      backgroundColor: "white",
      color: "black",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      border: "none",
      cursor: "pointer",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
  };

  return (
    <a
      href={videoUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={styles.videoThumbnail}
    >
      <img
        src={ThumbnailImage}
        alt="Video Thumbnail"
        style={styles.videoImage}
      />
      <div style={styles.playButton}>▶</div>
    </a>
  );
};

VideoThumbnail.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

export default VideoThumbnail;
