import React, { useEffect, useState } from "react";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    footer: {
      marginTop: "40px",
      marginBottom: "50px",
      display: "flex",
      flexDirection: isSmallScreen ? "column" : isMobile ? "row" : "row",
      justifyContent: isSmallScreen ? "center" : "space-between",
      alignItems: isSmallScreen ? "center" : "flex-end",
      width: "100%",
      boxSizing: "border-box",
      height: isSmallScreen ? "auto" : "150px",
      padding: "50px",
      textAlign: isSmallScreen ? "center" : "left",
    },
    textContainer: {
      marginBottom: isSmallScreen ? "20px" : "0",
      textAlign: isSmallScreen ? "center" : "left",
    },
    linksContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: isSmallScreen ? "center" : "flex-end",
      justifyContent: "center",
      gap: "15px",
      textAlign: isSmallScreen ? "center" : "right",
    },
    link: {
      color: "white",
      textDecoration: "none",
      fontSize: isSmallScreen ? "14px" : isMobile ? "14px" : "16px",
    },
    paragraph: {
      margin: 0,
      fontSize: isSmallScreen ? "16px" : isMobile ? "16px" : "18px",
      lineHeight: "1.6rem",
    },
    headline: {
      margin: "10px 0 0 0",
      fontSize: isSmallScreen ? "20px" : isMobile ? "20px" : "22px",
      fontWeight: "bold",
    },
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.textContainer}>
        <p style={styles.paragraph}>
          Whether you’re a brand or a retailer, Omi is designed to help your
          customers discover a new way to engage with you.
        </p>
        <h2 style={styles.headline}>Coming Spring '25</h2>
      </div>
      <div style={styles.linksContainer}>
        <a href="mailto:info@omi.fit" style={styles.link}>
          Contact
        </a>
        <a href="https://www.instagram.com/findyouromi" style={styles.link}>
          Instagram
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSc5CwffCEX0TZXp1aKrxXmReD2tng-DW8u7eavKPLGQmZXtOw/viewform?usp=header"
          style={styles.link}
        >
          Join Us
        </a>
      </div>
    </footer>
  );
};

export default Footer;
