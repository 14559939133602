import React, { useOptimistic } from "react";
import Avatar from "./avatar"; // Reuse the Avatar component
import userVero from "../../assets/landing/veronica_profile.png";
import userOmi from "../../assets/landing/omi_app_icon_round.png";
import brunaBear from "../../assets/landing/brunabear.jpg";

const Conversation = () => {
  const styles = {
    conversation: {
      display: "flex",
      flexDirection: "column",
      gap: "30px", // Space between messages
      marginBottom: "20px",
    },

    imageLinked: {
      height: "300px",
      borderRadius: "15px",
      alignSelf: "flex-end",
    },

    messageWrapper: (isLeftAligned) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: isLeftAligned ? "flex-start" : "flex-end",
      //alignSelf: isLeftAligned ? "flex-start" : "flex-end", // Align the message group
    }),
    messageBubble: (isLeftAligned) => ({
      backgroundColor: "rgba(255,255,255,0.1)",
      color: "white", // Text color contrast
      borderRadius: "15px",
      padding: "15px",
      textAlign: "left",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.4",
    }),
  };

  return (
    <div style={styles.conversation}>
      {/* Omi's Message */}
      <div style={styles.messageWrapper(true)}>
        <Avatar imageSrc={userOmi} name="Omi" isLeftAligned={true} />
        <div style={styles.messageBubble(true)}>
          Hey Veronica! How can I help you today?
        </div>
      </div>

      {/* Jalen's Message */}
      <div style={styles.messageWrapper(false)}>
        <Avatar imageSrc={userVero} name="Veronica" isLeftAligned={false} />
        <div style={styles.messageBubble(false)}>
          Ugh, I have a date right after work.<br></br> I don't want to look
          like I'm trying too hard but also want to look classy.<br></br> I love
          what she's wearing! Could we come up with something similar?
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <img style={styles.imageLinked} src={brunaBear} alt="Bruna Bear" />
      </div>

      {/* Omi's Message */}
      <div style={styles.messageWrapper(true)}>
        <Avatar imageSrc={userOmi} name="Omi" isLeftAligned={true} />
        <div style={styles.messageBubble(true)}>
          Love her outfit!<br></br> Try a cozy Maria McManus polo with trendy
          Loewe boyfriend jeans. Elevate the look with sleek Khaite knee boots
          for an effortlessly chic date night ensemble. Let me know if you need
          any other suggestions!
        </div>
      </div>
    </div>
  );
};

export default Conversation;
