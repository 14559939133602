import React from "react";
import PropTypes from "prop-types";

const Card = ({ imageSrc, label, subtitle, price }) => {
  const styles = {
    card: {
      border: "1px solid #e0e0e0",
      borderRadius: "20px",
      overflow: "hidden",
      width: "150px",
      height: "220px",
      textAlign: "center",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 20px",
    },
    cardImage: {
      width: "55%",
      height: "100px", // Fixed height to ensure uniformity across cards
      objectFit: "cover", // Ensures the image fits well
      margin: "20px 0 0",
    },
    cardContent: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    cardTitleContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      width: "100%",
    },
    cardLabel: {
      fontSize: "15px",
      fontWeight: "400",
      color: "#757575",
      textAlign: "left",
      margin: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardSubtitlePriceContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    cardSubtitle: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "13px",
      fontWeight: "500",
      textAlign: "left",
      color: "black",
      margin: 0,
    },
    cardPrice: {
      fontSize: "13px",
      fontWeight: "bold",
      color: "#000",
      textAlign: "right",
      margin: 0,
    },
  };

  return (
    <div style={styles.card}>
      <img src={imageSrc} alt={label} style={styles.cardImage} />
      <div style={styles.cardContent}>
        <div style={styles.cardTitleContainer}>
          <p style={styles.cardLabel}>{label}</p>
          <div style={styles.cardSubtitlePriceContainer}>
            <p style={styles.cardSubtitle}>{subtitle}</p>
            {/* <p style={styles.cardPrice}>${price}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default Card;
