import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import StylistChat from "./components/stylist-chat";
import "react-chat-elements/dist/main.css";

// routes
import LandingPage from "./pages/landing/landing-page";
import HomePage from "./pages/homepage";
import ItemsPage from "./pages/items-page"; //test old and new
import ItemDetails from "./pages/item-details-page";
import WishlistPage from "./pages/wishlist-page";
import BagPage from "./pages/bag-page";
import ItemDistributionTable from "./pages/item-distribution-table";

function App() {
  return (
    <div className="App">
      <Router>
        <MainRoutes />
      </Router>
    </div>
  );
}

function MainRoutes() {
  const location = useLocation();

  return (
    <>
      <Routes>
        {/* New Landing Page as the default */}
        <Route path="/" element={<LandingPage />} />

        {/* Move the current HomePage to /secret-demo */}
        <Route path="/secret-demo" element={<HomePage />} />

        {/* Other existing routes */}
        <Route path="/items-men" element={<ItemsPage gender="male" />} />
        <Route path="/items-women" element={<ItemsPage gender="female" />} />
        <Route path="/item-details/:id" element={<ItemDetails />} />
        <Route path="/wishlist" element={<WishlistPage />} />
        <Route path="/bag" element={<BagPage />} />
        <Route
          path="/item-table-distribution"
          element={<ItemDistributionTable />}
        />
      </Routes>

      {/* Show chat only if not on the landing page */}
      {location.pathname !== "/" && <StylistChat />}
    </>
  );
}

export default App;
