import React, { useOptimistic } from "react";
import Avatar from "./avatar"; // Reuse the Avatar component
import userJalen from "../../assets/landing/jalen_profile.png";
import userOmi from "../../assets/landing/omi_app_icon_round.png";

const Conversation = () => {
  const styles = {
    conversation: {
      display: "flex",
      flexDirection: "column",
      gap: "30px", // Space between messages
      marginBottom: "20px",
    },
    messageWrapper: (isLeftAligned) => ({
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      alignItems: isLeftAligned ? "flex-start" : "flex-end",
      //alignSelf: isLeftAligned ? "flex-start" : "flex-end", // Align the message group
    }),
    messageBubble: (isLeftAligned) => ({
      backgroundColor: "rgba(255,255,255,0.1)",
      color: "white", // Text color contrast
      borderRadius: "15px",
      padding: "15px",
      //textAlign: isLeftAligned ? "left" : "right",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.4",
    }),
  };

  return (
    <div style={styles.conversation}>
      {/* Jalen's Message */}
      <div style={styles.messageWrapper(false)}>
        <Avatar imageSrc={userJalen} name="Jalen" isLeftAligned={false} />
        <div style={styles.messageBubble(false)}>
          Can you help me find an outfit for a date?<br></br> I need outfits
          that are better than what I currently have cause my last date ghosted
          me smh.
        </div>
      </div>

      {/* Omi's Message */}
      <div style={styles.messageWrapper(true)}>
        <Avatar imageSrc={userOmi} name="Omi" isLeftAligned={true} />
        <div style={styles.messageBubble(true)}>
          Dude, forget the old outfits- it's time to step up your game!<br></br>{" "}
          Rock a sleek Our Legacy leather jacket with some badass distressed
          Celine Homme jeans. Throw on a pair of Maison Margiela sneakers to
          keep it fresh, and your date will be swooning faster than you can say
          "ghosted no more!" 😎
        </div>
      </div>
    </div>
  );
};

export default Conversation;
